export interface SchieberHand {
    /** client only */
    no: number;
    /** as on API */
    schieberHandId?: number;
    variante?: number;
    multiplikator: number;
    teamId?: number;
    playerDragsiId: number;
    playerGschobeId?: number;
    team1Result?: number;
    team2Result?: number;
    team1Wiis: Wiis[];
    team2Wiis: Wiis[];
  }
  
export interface SchieberSession {
    sessionId?: number;
    frzKarten: boolean;
    hand: SchieberHand[];
    pointsGoal: number;
    remarks: string;
    started?: string;
    team1Result: number;
    team2Result: number;
    seasonId?: number | string;
    attachment?: string;
  }
  export interface Wiis {
    betrag: number;
    playerId: number;
  }
  
  
export const varianteToFactor = (variante?: number) => {
  switch (variante) {
    case 0:
    case 1:
    case 2:
      return 1;
    case 3:
    case 4:
      return 2;
    case 5:
    case 6:
      return 3;
    default:
      return 1;
  }
};

export const weisReducerFn = (
  accumulator: number,
  currentWeis: { betrag: number; playerId: number }
) => accumulator + (currentWeis?.betrag ?? 0);

export const team1sumreducerFn = (
  accumulator: number,
  currentHand: SchieberHand
) =>
  accumulator +
  ((currentHand?.team1Result ?? 0) +
    (currentHand?.team1Wiis?.reduce(weisReducerFn, 0) ?? 0)) *
    varianteToFactor(currentHand.variante);

export const team2sumreducerFn = (
  accumulator: number,
  currentHand: SchieberHand
) =>
  accumulator +
  ((currentHand?.team2Result ?? 0) +
    (currentHand?.team2Wiis?.reduce(weisReducerFn, 0) ?? 0)) *
    varianteToFactor(currentHand.variante);