import { SchieberSession } from "./models";

export const state: SchieberSession = {
  sessionId: 8,
  frzKarten: true,
  hand: [
    {
      no: 1,
      multiplikator: 1,
      team1Result: 88,
      team2Result: 69,
      team1Wiis: [
        {
          playerId: 2,
          betrag: 20
        }
      ],
      team2Wiis: [],
      playerDragsiId: 3,
      variante: 2,
      playerGschobeId: 2
    },
    {
      no: 2,
      multiplikator: 2,
      playerDragsiId: 6,
      team1Wiis: [
        {
          playerId: 2,
          betrag: 50
        }
      ],
      team2Wiis: [],
      variante: 3,
      team1Result: 45,
      team2Result: 112
    },
    {
      no: 3,
      multiplikator: 3,
      playerDragsiId: 2,
      team1Wiis: [],
      team2Wiis: [],
      variante: 6,
      team1Result: 95,
      team2Result: 62
    },
    {
      no: 4,
      multiplikator: 1,
      playerDragsiId: 5,
      team1Wiis: [],
      team2Wiis: [],
      variante: 2,
      playerGschobeId: 6,
      team1Result: 91,
      team2Result: 66
    },
    {
      no: 5,
      multiplikator: 2,
      playerDragsiId: 3,
      team1Wiis: [
        {
          playerId: 2,
          betrag: 100
        }
      ],
      team2Wiis: [],
      variante: 3,
      team1Result: 257,
      team2Result: 0
    },
    {
      no: 6,
      multiplikator: 1,
      playerDragsiId: 6,
      team1Wiis: [
        {
          playerId: 2,
          betrag: 100
        }
      ],
      team2Wiis: [],
      playerGschobeId: 5,
      variante: 2,
      team1Result: 66,
      team2Result: 91
    },
    {
      no: 7,
      multiplikator: 2,
      playerDragsiId: 2,
      team1Wiis: [],
      team2Wiis: [],
      variante: 3,
      team1Result: 89,
      team2Result: 68
    },
    {
      no: 8,
      multiplikator: 2,
      playerDragsiId: 5,
      team1Wiis: [],
      team2Wiis: [],
      variante: 4,
      team1Result: 69,
      team2Result: 88
    },
    {
      no: 9,
      multiplikator: 3,
      playerDragsiId: 3,
      team1Wiis: [],
      team2Wiis: [
        {
          playerId: 6,
          betrag: 150
        }
      ],
      variante: 6,
      playerGschobeId: 2,
      team1Result: 32,
      team2Result: 125
    },
    {
      no: 10,
      multiplikator: 2,
      playerDragsiId: 6,
      team1Wiis: [],
      team2Wiis: [],
      variante: 4,
      team1Result: 26,
      team2Result: 131,
      playerGschobeId: 5
    },
    {
      no: 11,
      multiplikator: 3,
      playerDragsiId: 2,
      team1Wiis: [],
      team2Wiis: [
        {
          playerId: 5,
          betrag: 50
        }
      ],
      variante: 6,
      team1Result: 91,
      team2Result: 66
    },
    {
      no: 12,
      multiplikator: 2,
      playerDragsiId: 5,
      team1Wiis: [],
      team2Wiis: [],
      variante: 3,
      team1Result: 145,
      team2Result: 12
    }
  ],
  pointsGoal: 2500,
  remarks: "Jasserei vom 5.12.2021 15:00",
  team1Result: 0,
  team2Result: 0,
  seasonId: 1
};