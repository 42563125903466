import React from "react";

interface Props {
  size?: any;
}
export const FuenferBuendel = React.memo<Props>(function FuenferBuendel({
  size = "1em",
}) {
  return (
    <svg
      style={{ margin: ".2em" }}
      viewBox="0 0 256 256"
      height={size}
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <svg width="256" height="256" xmlns="http://www.w3.org/2000/svg">
        <g id="Default">
          <line
            x1="38"
            y1="20"
            x2="38"
            y2="192"
            stroke="white"
            strokeWidth="1em"
          />
          <line
            x1="98"
            y1="20"
            x2="98"
            y2="192"
            stroke="white"
            strokeWidth="1em"
          />
          <line
            x1="158"
            y1="20"
            x2="158"
            y2="192"
            stroke="white"
            strokeWidth="1em"
          />
          <line
            x1="218"
            y1="20"
            x2="218"
            y2="192"
            stroke="white"
            strokeWidth="1em"
          />
          <line
            x1="0"
            y1="192"
            x2="256"
            y2="20"
            stroke="white"
            strokeWidth="1em"
          />
        </g>
      </svg>
    </svg>
  );
});
