import React, { useEffect, useMemo, useState } from 'react';
import { SchieberStrichliJassTafel } from './SchieberStrichliJassTafel';
import { state } from './state';
import './App.css';
import { team1sumreducerFn, team2sumreducerFn } from './models';

function App() {
  const [game] = useState(state);
  const [index, setIndex] = useState(0);
  const [t1p1] = useState("M");
  const [t1p2] = useState("G");
  const [t2p1] = useState("G");
  const [t2p2] = useState("V");
  useEffect(() => {
    const interval = setInterval(() => {
      setIndex(index => index > 12 ? 0 : index + 1);
      console.warn("ping " + index);
    }, 3000);
    return () => clearInterval(interval);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const current = useMemo(() => { return { ...game, hand: game.hand.slice(0, index) }; }, [index, game]);
  return (
    <>
      <div style={{ width: "100%", color: "white", background: "#538a53", margin: "0"}} >
        <div style={{ margin: "auto", paddingTop: "0.5em", textAlign: "center" }} >
          <h2>Bald wird weder gjasset!</h2>
          <p>Do chan bald jede Jasser ganz eifach sini Wiis schribe ond sini Stich zelle.</p>
          <p> Wer het Ende Johr am meiste gjasset, punktet, agseit, gwise?</p>
        </div>
        <div style={{ width: "100%", margin: "auto" }} >
          <p style={{textAlign: "left", fontSize: "2em", fontWeight: "bold", margin: ".5em", padding: ".25em" }}>
            {current.hand.reduce(team1sumreducerFn, 0)}
            <span style={{float: "right"}}>
              {current.hand.reduce(team2sumreducerFn, 0)}
            </span>
          </p>
        </div>
      </div>
      <div style={{ width: "100%"}} >
        <SchieberStrichliJassTafel session={current} t1p1={t1p1} t1p2={t1p2} t2p1={t2p1} t2p2={t2p2} />
      </div>
    </>
  );
}

export default App;
 