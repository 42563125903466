import React, { useMemo } from "react";
import { SchieberHand, SchieberSession, Wiis } from "./models";
import { JasstafelNotation } from "./JasstafelNotation";
import { JasstafelZContainer } from "./JasstafelZContainer";
import './App.css';

interface Strichlitructure {
  x: number;
  v: number;
  c: number;
  l: number;
  zwanzig: number;
  rest: number;
}
function initStructure(): Strichlitructure {
  return {
    x: 0,
    v: 0,
    c: 0,
    l: 0,
    zwanzig: 0,
    rest: 0,
  };
}
function structureResult(
  r: { t1: Strichlitructure; t2: Strichlitructure },
  hand: SchieberHand
) {
  r.t1 = structureTeamResult(
    r.t1,
    hand.multiplikator,
    hand.team1Result ?? 0,
    hand.team1Wiis
  );
  r.t2 = structureTeamResult(
    r.t2,
    hand.multiplikator,
    hand.team2Result ?? 0,
    hand.team2Wiis
  );
  return r;
}
function structureTeamResult(
  t: Strichlitructure,
  multiplikator: number,
  result: number,
  wiis: Wiis[]
) {
  const total =
    (wiis.reduce((p, c) => p + c.betrag, 0) + (result ?? 0)) * multiplikator +
    t.rest;
  if (total < 0) {
    t.rest = total;
    return t;
  }
  t.x += Math.floor(total / 1000);
  t.v += Math.floor((total % 1000) / 500);
  t.c += Math.floor(((total % 1000) % 500) / 100);
  t.l += Math.floor((((total % 1000) % 500) % 100) / 50);
  t.zwanzig += Math.floor(((((total % 1000) % 500) % 100) % 50) / 20);
  const rest = ((((total % 1000) % 500) % 100) % 50) % 20;
  if (rest > 10) {
    t.zwanzig++;
    t.rest = rest - 20;
  } else {
    t.rest = rest;
  }

  return t;
}

interface Props {
  session: SchieberSession;
  t1p1: string;
  t1p2: string;
  t2p1: string;
  t2p2: string;
}
function SchieberStrichliJassTafelInternal(props: Props) {


  const r = useMemo(
    () =>
      props.session.hand.reduce((p: any, c: any) => structureResult(p, c), {
        t1: initStructure(),
        t2: initStructure(),
      }),
    [props]
  );

  return (
    <JasstafelZContainer>
      <div
        style={{
          position: "absolute",
          top: "51%",
          right: "13%",
          textAlign: "right",
          border: "solid white",
          paddingLeft: ".2em",
          paddingRight: ".2em",
          borderRadius: ".5em",
          lineHeight: "1.2em",
          fontSize: ".8em",
          fontFamily: "Caveat, cursive",
          fontWeight: "bold",
        }}
      >
        {props.t1p1}+{props.t1p2}
      </div>
      <div
        className={"jasstafelline"}
        style={{
          top: "54%",
          left: "20%",
          width: "60%",
        }}
      >
        <JasstafelNotation char={"X"} bundle={0} count={r.t1.x} />
        <JasstafelNotation char={"V"} bundle={0} count={r.t1.v} />
        <JasstafelNotation char={"I"} bundle={5} count={r.t1.c} />
      </div>
      <div
        className={"jasstafelline"}
        style={{
          top: "66.5%",
          left: "20%",
          width: "60%",
          WebkitTransform: "rotate(-33deg)",
        }}
      >
        <JasstafelNotation char={"I"} bundle={2} count={r.t1.l} />
      </div>
      <div
        className={"jasstafelline"}
        style={{
          top: "80%",
          left: "20%",
          width: "60%",
        }}
      >
        <JasstafelNotation char={"I"} bundle={5} count={r.t1.zwanzig} />
      </div>
      <div
        className={"jasstafelline"}
        style={{
          top: "70%",
          right: "13%",
          width: "26%",
          fontFamily: "Caveat, cursive",
          fontWeight: "bold",
        }}
      >
        {r.t1.rest === 0 ? "" : r.t1.rest}
      </div>

      <div
        style={{
          position: "absolute",
          top: "44%",
          left: "13%",
          textAlign: "right",
          border: "solid white",
          paddingLeft: ".2em",
          paddingRight: ".2em",
          borderRadius: ".5em",
          lineHeight: "1.2em",
          fontSize: ".8em",
          fontFamily: "Caveat, cursive",
          fontWeight: "bold",
          WebkitTransform: "rotate(-180deg)",
        }}
      >
        {props.t2p1}+{props.t2p2}
      </div>
      <div
        className={"jasstafelline"}
        style={{
          top: "38%",
          left: "20%",
          width: "60%",
          WebkitTransform: "rotate(-180deg)",
        }}
      >
        <JasstafelNotation char={"X"} bundle={0} count={r.t2.x} />
        <JasstafelNotation char={"V"} bundle={0} count={r.t2.v} />
        <JasstafelNotation char={"I"} bundle={5} count={r.t2.c} />
      </div>
      <div
        className={"jasstafelline"}
        style={{
          top: "24.5%",
          left: "20%",
          width: "60%",
          WebkitTransform: "rotate(-213deg)",
        }}
      >
        <JasstafelNotation char={"I"} bundle={2} count={r.t2.l} />
      </div>
      <div
        className={"jasstafelline"}
        style={{
          top: "12.5%",
          left: "20%",
          width: "60%",
          WebkitTransform: "rotate(-180deg)",
        }}
      >
        <JasstafelNotation char={"I"} bundle={5} count={r.t2.zwanzig} />
      </div>

      <div
        className={"jasstafelline"}
        style={{
          top: "24.5%",
          left: "13%",
          width: "26%",
          WebkitTransform: "rotate(-180deg)",
          fontFamily: "Caveat, cursive",
          fontWeight: "bold",
        }}
      >
        {r.t2.rest === 0 ? "" : r.t2.rest}
      </div>
    </JasstafelZContainer>
  );
}

export function SchieberStrichliJassTafel(props: Props) {
  return props?.session?.hand ? (
    <SchieberStrichliJassTafelInternal {...props} />
  ) : (
    <div>-</div>
  );
}
