import React, { useMemo } from "react";
import { FuenferBuendel } from "./FuenferBuendel";

interface Props {
  char: string;
  bundle: 0 | 2 | 5;
  count: number;
}
export const JasstafelNotation = (props: Props) => {
  const bundles = useMemo(
    () =>
      props.bundle ? [...Array(Math.floor(props.count / props.bundle))] : [],
    [props]
  );
  const rest = useMemo(
    () => [...Array(props.bundle ? props.count % props.bundle : props.count)],
    [props]
  );

  return (
    <>
      {bundles.map((v, i) =>
        props.bundle === 2 ? "X" : <FuenferBuendel key={i} />
      )}
      {rest.map(() => props.char)}
    </>
  );
};
